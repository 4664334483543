// i18next-extract-mark-ns-start support-customer-support

import { SUPPORT_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { externalLocaleMapping } from '@utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';

const customerSupportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const {
		loginWithRedirect,
		user
	} = useAuth0();

	const { t, i18n } = useTranslation();
	const locale = externalLocaleMapping(i18n.resolvedLanguage)

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							Existing customers can login to the Realis Simulation
							Customer Portal using their{' '}
							<a href={`https://support.realis-simulation.com/${locale}/support/solutions/articles/60000715610`}>
								RS Account
							</a>{' '}
							to submit support tickets, check the status of their tickets
							and gain access to our extensive knowledge library.
						</p>
						<table className="border_1 cellpadding_0 cellspacing_0">
							<tbody>
								<tr>
									<td>
										<a href={`https://support.realis-simulation.com/${locale}/support/tickets/new`}>
											Submit a ticket
										</a>
									</td>
									<td>
										Submit a new support ticket and you will be
										notified when your enquiry has been assigned.
										Alternatively, you can contact us using{' '}
										<a href="mailto:support@realis-simulation.com?subject=Support%20portal%20enquiry,%20Software%20website">
											support@realis-simulation.com
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<a href={`https://support.realis-simulation.com/${locale}/support/tickets`}>
											My tickets
										</a>
									</td>
									<td>Track the progress of your support tickets</td>
								</tr>
								<tr>
									<td>
										<a href={`https://support.realis-simulation.com/${locale}/support/solutions/60000321806`}>
											FAQ
										</a>
									</td>
									<td>
										Our Frequently Asked Questions may provide you
										with an answer to your enquiry without
										submitting a support ticket
									</td>
								</tr>
								<tr>
									<td>
										<Link to={LINKS.SUPPORT_LATEST}>Download {LINKS.SUPPORT_LATEST_VERSION}</Link>
									</td>
									<td>Download the latest software release</td>
								</tr>
								<tr>
									<td>
										<a href={`https://support.realis-simulation.com/${locale}/support/solutions`}>
											Knowledge library
										</a>
									</td>
									<td>
										Gain access to our extensive knowledge library
									</td>
								</tr>
								<tr>
									<td>
										<a href={`https://support.realis-simulation.com/${locale}/support/home`}>
											Search
										</a>
									</td>
									<td>Search the customer support portal</td>
								</tr>
							</tbody>
						</table>
					</Trans>
					{
						!user &&
						<Trans i18nKey="content_3">
							<p>
								<strong>
									<em>
										If you are an existing customer and do not have an RS
										Account, or new to Realis Simulation and would like access to some
										content of our knowledge library then please
										register by clicking on the link below. Registration
										is quick and easy.
									</em>
								</strong>
							</p>
						</Trans>
					}
					{
						!user &&
						<p>
							<button className="button" onClick={() => loginWithRedirect({
								authorizationParams: {
									screen_hint: 'signup',
									ui_locales: i18n.resolvedLanguage,
									mode: 'signUp',
								}
							})}>{t("_Signup") /* i18next-extract-disable-line */}</button>
						</p>
					}
					<Trans i18nKey="content_1">
						<p>
							For any other query, or if you are not an existing customer,
							please contact us using the email{' '}
							<a href="mailto:contact@realis-simulation.com?subject=Support%20portal%20enquiry,%20Software%20website">
								contact@realis-simulation.com
							</a>.
						</p>
						<p>
							The Export Control Classification Number (ECCN) for all
							Realis Simulation products is&nbsp;<strong>5D992</strong>.
							For further information on ECCN please click &nbsp;
							<a href="https://www.bis.doc.gov/index.php/licensing/commerce-control-list-classification/export-control-classification-number-eccn">
								here
							</a>.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_SoftwareUpdatesTraining")}
						className="widget__sibling__pages">
						<AssetList>
							{SUPPORT_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default customerSupportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support-customer-support", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/singlehero.png" }) {
			...imageBreaker
		}
	}
`;
